





















































































import api from "@/api";
import {Component, Ref, Vue} from "vue-property-decorator";
import {SignupInfoDto} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import {regionData} from "element-china-area-data";

@Component({
  components: {ExportButton, AgileAuditTag, AbSelect, PagedTableView}
})
export default class HelpAppliesList extends Vue {
  @Ref() readonly pagedTableView!: any;
  editId = 0;
  options: any = regionData;
  queryForm = {
    userType: "",
    userName: "",
    nickName: "",
    nation: "",
    phone: ""
  }

  fetchData(params: any) {
    return api.signupInfo.getAll(params);
  }


  // async created() {
  //
  //   if (this.$route.params.id) {
  //     this.dataId = Number(this.$route.params.id);
  //     await api.helpApplies.get({id: this.dataId}).then((res) => {
  //       this.form = {...res};
  //       this.selectedOptions = [
  //         res!.provinceId + "",
  //         res!.cityId + "",
  //         res!.areaId + ""
  //       ];
  //     });
  //   }
  //
  // }

  // 跳转详情页
  handleDetail(index: number, row: SignupInfoDto) {
    this.editId = row.id!;
    this.$router.push({
      name: "signUpInfoDetail",
      params: {id: row.id!.toString()}
    });
  }

}
